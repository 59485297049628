import request from '@/utils/request'
import qs from 'qs'
// 云闪付支付
export function OrderDates(orderId, txnAmt) {
  let data = {
    orderId, txnAmt
  }
  data = qs.stringify(data)
  return request({
    url: '/UnionPay/unionPay',
    method: 'post',
    data
  })
}
// 平台审核通过 微信支付
export function OrderDatesuerl(work_order,author_id) {
  let data = {
    work_order,author_id
  }
  data = qs.stringify(data)
  return request({
    url: '/WxPay/getpayUrl',
    method: 'post',
    data
  })
}
// 生成二维码
export function OrderDatesuerls(code_url) {
  let data = {
    code_url
  }
  data = qs.stringify(data)
  return request({
    url: '/WxPay/getCheckOderImage ',
    method: 'post',
    data
  })
}
// 智能查重支付
export function getCheckpayUrl(author_id) {
  let data = {
    author_id
  }
  data = qs.stringify(data)
  return request({
    url: '/WxPay/getCheckpayUrl ',
    method: 'post',
    data
  })
}
// 支付查询
export function checkpayStatus(orderNo) {
  let data = {
    orderNo
  }
  data = qs.stringify(data)
  return request({
    url: '/WxPay/checkpayStatus ',
    method: 'post',
    data
  })
}
// 申请服务商
export function applyPayUrl(author_id,work_order) {
  let data = {
    author_id,work_order
  }
  data = qs.stringify(data)
  return request({
    url: '/WxPay/applyPayUrl',
    method: 'post',
    data
  })
}
// 作者支付金额
export function getBalances (works_id) {
  let data = {
    works_id
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/getBalances',
    method: 'post',
    data
  })
}
// 作者提现
export function authorWithdraw (userId,num,withdrawName,withdrawAccount,withdrawBank) {
  let data = {
    userId,num,withdrawName,withdrawAccount,withdrawBank
  }
  data = qs.stringify(data)
  return request({
    url: '/author/authorWithdraw',
    method: 'post',
    data
  })
}