<template>
  <div style="padding: 20px">
    <div class="qurentitle">确认订单</div>
    <div class="chargeorder">
      <p>订单名称: <span>智能查重费用</span></p>
      <p>订单金额：<span style="color: red">￥300</span></p>
    </div>
    <div v-if="flag == 0">
      <div class="paystyle">
        <p>支付方式</p>
        <ul>
          <li
            v-for="(item, index) in paylist"
            @click="nowactive(index)"
            :class="index == flag ? 'nowactive' : ''"
          >
            <img :src="item.src" alt="" />{{ item.text }}
          </li>
        </ul>
      </div>
      <div class="pay">
        <img :src="url" alt="" />
        <p>请使用手机打开微信扫描二维码完成支付备份</p>
      </div>
    </div>
    <div v-if="flag == 1">
      <div class="paystyle">
        <p>支付方式</p>
        <ul>
          <li
            v-for="(item, index) in paylist"
            @click="nowactive(index)"
            :class="index == flag ? 'nowactive' : ''"
          >
            <img :src="item.src" alt="" />{{ item.text }}
          </li>
        </ul>
      </div>
      <div class="pay">
        <img src="../../../assets/slices/ewm.png" alt="" />
        <p>请使用手机打开支付宝扫描二维码完成支付备份</p>
      </div>
    </div>
    <div v-if="flag == 2">
      <div class="paystyle">
        <p>支付方式</p>
        <ul>
          <li
            v-for="(item, index) in paylist"
            @click="nowactive(index)"
            :class="index == flag ? 'nowactive' : ''"
          >
            <img :src="item.src" alt="" />{{ item.text }}
          </li>
        </ul>
      </div>
      <div class="pay">
        <img src="../../../assets/slices/ewm.png" alt="" />
        <p>请使用手机打开云闪付扫描二维码完成支付备份</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCheckpayUrl } from "../../../API/zhifu.js"
export default {
  components: {

  },
  data () {
    return {
      flag: 0,
      paylist: [
        { "src": require("../../../assets/slices/wx.png"), "text": "微信支付" },
        { "src": require("../../../assets/slices/zfb.png"), "text": "支付宝" },
        // { "src": require("../../../assets/slices/ysf.png"), "text": "云闪付" }
      ],
      fei: '期刊费用',
      url: '',
      wxsrc: '',
    }

  },
  mounted () {
    console.log(this.$route.query.num);
  },
  methods: {
    nowactive (e) {
      console.log(e)
      this.flag = e
      // 如果 选择微信下标为0 请求微信支付二维码， 先点微信的请求，把参数传到请求参数接口，请求成功后调取第一个接口的二维码链接，返回base64格式 前台解析base64 转url 渲染在接口
      if (this.flag == 0) {
        var id = localStorage.getItem('ZZId')
        getCheckpayUrl(id).then(res => {
          console.log(res);
          this.url = res.data.data.code_url
          this.$axios({
            method: "post",
            url: "/WxPay/getCheckOderImage?codeUrl=" + this.url,
            // responseType: 'blob',
          }).then(res => {
            console.log(res)
            // const { data, headers } = res.data
            // const blob = new Blob([data], { type: headers['content-type'] })
            // this.wxsrc = window.URL.createObjectURL(blob)

            console.log(this.wxsrc);


          })

        //    OrderDatesuerls(this.url).then(res =>{
        //      console.log(res)
        //   this.wxsrc = res
        // })
        })
        // this.$axios({
        //   method: "post",
        //   url: "http://182.92.242.124:8768/WxPay/getpayUrl?orderNo=20210220002525252&author_id=4",
        // }).then(res => {
        //   console.log(res);
        //   this.url = res.data.data.code_url
        //   console.log(res.data.data.code_url);
        //   this.$axios({
        //     method: "post",
        //     url: "http://182.92.242.124:8768/WxPay/wxPay/getImage?codeUrl=" + this.url,
        //     // responseType: 'blob',
        //   }).then(res => {
        //     console.log(res)
        //     // const { data, headers } = res.data
        //     // const blob = new Blob([data], { type: headers['content-type'] })
        //     // this.wxsrc = window.URL.createObjectURL(blob)

        //     console.log(this.wxsrc);
        //   })
        //})
      }
    }
  }
}
</script>

<style>
.qurentitle {
  padding: 10px;
  border-bottom: 1px solid #e6e6e6;
  font-weight: 800;
}
.chargeorder {
  line-height: 50px;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px;
  font-size: 14px;
}
.chargeorder p {
  color: #999;
}
.chargeorder p span {
  color: #000;
}
.paystyle {
  line-height: 50px;
  padding: 10px;
  font-size: 14px;
  color: #999;
}
.paystyle ul {
  display: flex;
}
.paystyle ul li {
  flex: 1;
  margin: 0 5px;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #dbdbdb;
  text-align: center;
  cursor: pointer;
}
.paystyle ul li img {
  vertical-align: middle;
}
.nowactive {
  flex: 1;
  margin: 0 5px;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #2f7df7;
  background: rgba(47, 125, 247, 0.16);
  text-align: center;
  cursor: pointer;
}
.pay {
  text-align: center;
  padding: 20px;
}
.pay img {
  width: 30%;
  margin-bottom: 10px;
}
.pay p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.bt {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.xs .author {
  margin-right: 10px;
}
.file {
  width: 50%;
  height: 40px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.file span {
  font-size: 14px;
}
</style>